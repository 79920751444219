<script setup lang="ts">
import { computed, ref } from "vue";
import { reset } from "@formkit/core";
import { TgButton } from "@/components/common";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { InteledeskParams } from "@/bapi-client/types";
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "open", value: boolean): void;
}>();

const formType = ref("BUG");
const formTypeOptions = {
  BUG: "Bug",
  FEATURE: "Feature",
  MANUAL_ADJUSTMENT: "Manual Adjustment",
};

const requestTypeOptions = {
  NEW: "New Feature",
  EXTENSION: "Extension of existing feature",
  MISSING: "Missing feature from existing product",
};

const productModuleOptions = {
  ANALYTICS: "Analytics",
  BOOKING: "Booking",
  CUSTOMER_SUPPORT: "Customer Support",
  DASHBOARD: "Dashboard",
  GENERAL_PLATFORM: "General Platform",
  INTERNAL_TOOLS: "Internal Tools",
  PRICING: "Pricing",
  REPORTING: "Reporting",
  TELEMATICS: "Telematics",
  VISIBILITY: "Visibility",
  OTHER: "Other",
};

const priorityOptions = {
  CRITICAL: "Critical",
  IMPORTANT: "Important",
  NICE_TO_HAVE: "Nice to have",
};
const teamOptions = {
  DATA: "Data",
  FRONTEND_ENGINEERING: "Frontend Engineering",
  BACKEND_ENGINEERING: "Backend Engineering",
  MARKETING: "Marketing",
  PRODUCT: "Product",
  UNKNOWN: "Unknown",
};

const isLoading = ref(false);
const formResponse = ref<string | null>(null);
const githubUrl = ref("");

const expectationsLabel = computed(() => {
  return formType.value === "MANUAL_ADJUSTMENT" ? "What do you want to make a change to?" : "What should happen?";
});

const location = computed(() => {
  return window.location.href;
});

async function handleSubmit(data: InteledeskParams) {
  isLoading.value = true;
  const result = await useBapi(BAPI_COMMANDS.INTELEDESK_SUBMIT, data);
  if (!result.success) {
    formResponse.value = "error";
    isLoading.value = false;
    return;
  }
  formResponse.value = "success";
  isLoading.value = false;
  githubUrl.value = result.githubURL;
  reset("inteledesk-form");
}

function popoverToggle(e: ToggleEvent) {
  const body = document.querySelector("body");
  if (body) {
    if (e.newState === "open") {
      emit("open", true);
      if (body) {
        body.style.overflow = "hidden";
      }
      return;
    }
    emit("close");
    body.style.overflow = "auto";
  }
}
</script>

<template>
  <div>
    <button popovertarget="inteledeskpopover" class="flex max-h-14 p-3 text-xl">
      <img src="/src/assets/in-tele-desk.png" class="mr-3 w-6" />
      <span class="text-blue-sky-300">InTeleDesk</span>
    </button>
    <div
      id="inteledeskpopover"
      popover
      class="inset-[unset] -bottom-px left-64 max-h-screen w-[520px] border border-gray-400 bg-white p-3"
      @toggle="popoverToggle"
    >
      <div class="flex flex-row items-center">
        <div class="mr-2">
          <img src="/src/assets/in-tele-desk_black.png" class="w-4" />
        </div>
        <span class="text-lg">InTeleDesk</span>
      </div>
      <hr class="mb-6 mt-3" />
      <FormKit id="inteledesk-form" type="form" :actions="false" @submit="handleSubmit">
        <template #default="{ state: { valid } }">
          <FormKit
            v-model="formType as any"
            enforced
            type="togglebuttons"
            name="category"
            :options="formTypeOptions"
            outer-class="mb-3"
            validation="required"
          />

          <FormKit type="text" name="title" label="Title" outer-class="mb-3" validation="required" />

          <FormKit
            v-if="formType === 'FEATURE'"
            type="select"
            name="feature_type"
            label="Request Type"
            value="NEW"
            :options="requestTypeOptions"
            outer-class="mb-3"
            validation="required"
          />
          <FormKit
            type="select"
            name="module"
            label="Product Module"
            value="DASHBOARD"
            :options="productModuleOptions"
            outer-class="mb-3"
            validation="required"
          />
          <FormKit type="text" name="user_action" label="What happened?" outer-class="mb-3" validation="required" />
          <FormKit
            type="text"
            name="expectations"
            :label="expectationsLabel"
            outer-class="mb-3"
            validation="required"
          />

          <div class="flex flex-row gap-3 pb-3">
            <FormKit type="text" name="needed_by" label="Who needs this?" validation="required" />
            <FormKit
              type="select"
              name="audience"
              label="Audience"
              value="INTERNAL"
              :options="{
                INTERNAL: 'Internal',
                EXTERNAL: 'External',
              }"
              validation="required"
            />
          </div>
          <div class="flex flex-row gap-3 pb-3">
            <FormKit type="date" name="deadline" label="Deadline" />
            <FormKit type="text" name="deadline_reason" label="Deadline reason" />
          </div>
          <div class="flex flex-row gap-3 pb-3">
            <FormKit
              type="select"
              name="severity"
              label="Severity"
              value="IMPORTANT"
              :options="priorityOptions"
              validation="required"
            />
            <FormKit
              type="select"
              name="assign_to_team"
              label="Assign to Team"
              value="PRODUCT"
              :options="teamOptions"
              validation="required"
            />
            <FormKit data-testid="inteledesk-company-id" type="hidden" :value="userStore.companyId" name="company_id" />
            <FormKit data-testid="inteledesk-user-id" type="hidden" :value="userStore.user?.userId" name="requestor" />
            <FormKit data-testid="inteledesk-page-url" type="hidden" :value="location" name="page_url" />
          </div>

          <hr class="mb-3 mt-5" />

          <FormKit type="text" name="url" label="URL (optional)" outer-class="mb-3" />

          <FormKit v-if="formType === 'FEATURE'" type="text" name="jamstack_url" label="Jam Link" outer-class="mb-3" />
          <FormKit
            v-if="formType === 'BUG'"
            type="text"
            name="jamstack_url"
            label="Jam Link"
            outer-class="mb-3"
            validation="required"
          />

          <FormKit
            type="text"
            name="additional_details"
            label="Any addtional details you'd like to share? (optional)"
            outer-class="mb-3"
          />

          <div class="mt-3 w-full">
            <TgButton
              data-testid="inteledesk-form-submit"
              class="w-full"
              is-condensed
              :disabled="!valid"
              :is-loading="isLoading"
              @submit="handleSubmit"
            >
              Submit
            </TgButton>
          </div>
          <div v-if="formResponse === 'success'">
            <div class="mt-3 flex flex-row items-center justify-center text-green-teal">
              <i class="fa-solid fa-check mr-2" />
              <span>Thank you for your submission</span>
            </div>
            <div class="text-center">
              <a v-if="githubUrl" :href="githubUrl" target="_blank" class="text-blue-300">
                {{ githubUrl }}
              </a>
            </div>
          </div>
          <div v-if="formResponse === 'error'" class="mt-3 text-center text-red-500">
            <span>Something went wrong. Please try again later.</span>
          </div>
        </template>
      </FormKit>
    </div>
  </div>
</template>
