<script setup lang="ts">
import SidebarDesktopMenu from "./SidebarDesktopMenu.vue";
import SidebarMobileMenu from "./SidebarMobileMenu.vue";
interface Props {
  sidebarOpen: boolean;
}

defineProps<Props>();
defineEmits<{ (event: "close-sidebar"): void; (event: "open-sidebar"): void }>();
</script>

<template>
  <SidebarMobileMenu :sidebar-open="sidebarOpen" @close-sidebar="$emit('close-sidebar')">
    <template #navigation="{ isOpen }">
      <slot :is-open="isOpen" />
    </template>
  </SidebarMobileMenu>
  <SidebarDesktopMenu :sidebar-open="sidebarOpen" @close-sidebar="$emit('close-sidebar')">
    <template #navigation="{ isOpen }">
      <slot :is-open="isOpen" />
    </template>
  </SidebarDesktopMenu>
</template>
