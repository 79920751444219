<script setup lang="ts">
import { ButtonColor, colorClasses } from "@/components/common/buttonProps";
type Props = { color: ButtonColor; isSmall: boolean };

defineProps<Props>();
</script>

<template>
  <button
    class="relative inline-flex grow-0 cursor-pointer items-center gap-3 p-3 font-semibold transition-colors"
    :class="[isSmall ? 'h-9 text-base' : 'h-11 text-lg', colorClasses(color, false)]"
  >
    <slot />
  </button>
</template>
